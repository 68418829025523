
















































































































































































































































































































































































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { listMixin } from "@/mixins/listMixin";
import { ZuzhijiagouList } from "@/request/manage";
import { CreatorList } from "@/request/common";
import {
  PatientList,
  AddZhenci,
  GetTemplatesOfGroup,
  GetTopicsOfTemplate,
  PostHuishouzhan,
  ExportOneCase,
  GetZhencis,
  GetCaozuoTemplate,
} from "@/request/storehouse";
import { getTopicOpt } from "@/request/topic";
import { GetCurrentUserData } from "@/request/account";
import MainHeader from "@/components/main-header/main-header.vue";
import NavLeft from "@/components/nav-left/nav-left.vue";
import SearchJigou from "@/components/search-jigou.vue";
import SearchCreator from "@/components/search-creator.vue";
import AddPatient from "../../components/add-patient/add-patient.vue";
import ComposePatient from "../../components/compose-patient/compose-patient.vue";
import SelectMultiSearch from "../components/select-multi-search.vue";
@Component({
  components: {
    SelectMultiSearch,
    MainHeader,
    NavLeft,
    SearchJigou,
    SearchCreator,
    AddPatient,
    ComposePatient,
  },
})
export default class Name extends mixins(listMixin) {
  private topicOpt: any = [];
  private institutions: any = [];
  private creators: any[] = [];
  private patients: any = [];
  private cPatient: any = {};
  private ifShowAddPatient: boolean = false;
  private ifShowComposePatient: boolean = false;
  private ifShowPrimarySearch: boolean = false;
  private msgType: any = "基本信息";
  private searchedTemplates: any[] = [];
  private zhenciOpt: any = [];
  private caozuoTems: any = [];
  private get center() {
    return this.$store.state.center;
  }
  private get user() {
    return this.$store.state.user;
  }
  private get configuration() {
    return this.$store.state.configuration;
  }
  private select2Change(item: any) {
    if (item.selected.type == "下拉多选") {
      item["result"] = {
        condition: "",
        value: [],
      };
    } else {
      item["result"] = {
        condition: "",
        value: "",
      };
    }
    this.$forceUpdate();
  }
  private getZhenciOpt() {
    const params: any = {
      params: {
        project_id: this.center.project_id,
      },
    };
    GetZhencis(this, params).then((res: any) => {
      this.zhenciOpt = res;
    });
  }
  private jumpHuishouzhan() {
    localStorage.setItem("recycleFilter", "");
    this.$router.push("/main/storehouse/case/recycle");
  }
  private del(item: any) {
    this.$confirm("您确定要将该患者及其数据放入回收站么？", "放入回收站", {
      customClass: "commonConfirm",
    })
      .then(() => {
        const params: any = {
          project_id: item.project_id,
          patient_id: item.patient_id,
          operate: "放入",
        };
        const loading = this.$loading({
          lock: true,
          text: "加载中……",
          spinner: "el-icon-loading",
          customClass: "loadClass",
          background: "rgba(255, 255, 255, 0.7)",
        });
        PostHuishouzhan(this, params, loading).then((res: any) => {
          loading.close();
          this.$message.success("放入回收站成功！");
          this.getList();
        });
      })
      .catch(() => {
        console.log("取消");
      });
  }
  private forceUpdate() {
    this.$forceUpdate();
  }
  private exportCase(item: any) {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      project_id: item.project_id,
      patient_id: item.patient_id,
    };
    ExportOneCase(this, params, loading)
      .then((res: any) => {
        loading.close();
        this.$message.success("导出成功");
        window.open(res.file_url);
      })
      .catch(() => {
        loading.close();
      });
  }
  /**
   * @description 添加研究指标变量
   */
  private addTiaojian() {
    this.filters["研究指标"].push({ result: {}, selected: {} });
    this.forceUpdate();
  }
  private addCancel() {
    this.ifShowAddPatient = false;
  }
  private composeCancel() {
    this.ifShowComposePatient = false;
  }
  private compose(item: any) {
    this.cPatient = item;
    this.ifShowComposePatient = true;
    this.msgType = "基本信息";
  }
  private composeCaozuo(item: any, tem: any) {
    this.cPatient = item;
    this.ifShowComposePatient = true;
    this.msgType = tem["名称"];
  }
  private jumpDataExport() {
    this.$router.push({
      path: "/main/storehouse/case/data-export",
    });
  }
  /**
   * @description 删除一个条件
   */
  private deleteTiaojian(index: any) {
    this.filters["研究指标"].splice(index, 1);
  }
  /**
   * @description 搜索模板下具体的字段
   */
  private searchItems(l: any) {
    const params: any = {
      params: {
        template_id: l.template_id,
      },
    };
    GetTopicsOfTemplate(this, params).then((data: any) => {
      l.items = data;
      l.selected = {};
      l.result = {};
      this.$forceUpdate();
    });
  }
  /**
   * @description 获得可选机构列表
   */
  private fetchHospitals() {
    const params: any = {
      params: {
        project_id: this.center.project_id,
      },
    };
    if (this.filters.project_id == "全部") {
      params.params.project_id = "";
    }
    ZuzhijiagouList(this, params).then((data: any) => {
      this.institutions = data;
      this.getCreatorsList();
    });
  }
  /**
   * @description 获得可选创建者列表
   */
  private getCreatorsList() {
    const params: any = {
      project_id: this.center.project_id,
      institution_ids: [this.filters.institution_id],
    };
    if (this.filters.project_id == "全部") {
      params.project_id = "";
    }
    CreatorList(this, params).then((data: any) => {
      this.creators = data;
    });
  }
  /**
   * @description 选择机构改变
   */
  private institutionChange(e: any) {
    this.filters.institution_id = e;
    // 重置创建人
    this.filters.user_ids = [];
    // 搜索
    this.search();
  }
  /**
   * @description 选择创建人改变
   */
  private creatorChange(e: any) {
    this.filters.user_ids = e;
    // 搜索
    this.search();
  }
  /**
   * @description 打开高级筛选
   */
  private openPrimarySearch() {
    this.fetchTemplates();
    this.ifShowPrimarySearch = true;
  }
  /**
   * @description 重置高级搜索
   */
  private resetPrimarySearch() {
    this.filters.ifPrimarySearched = false;
    this.ifShowPrimarySearch = false;
    this.filters.timeWay = "";
    this.filters.time = [];
    this.filters["条件"] = "";
    this.filters["研究指标"] = [];
    this.search();
  }
  /**
   * @description 取消高级筛选
   */
  private cancelPrimarySearch() {
    this.ifShowPrimarySearch = false;
  }
  /**
   * @description 确定高级筛选
   */
  private confirmPrimarySearch() {
    this.ifShowPrimarySearch = false;
    this.filters.current_page = 1;
    this.filters.ifPrimarySearched = true;
    this.getList();
  }
  /**
   * @description 获取当前分组下的可选模板列表
   */
  private fetchTemplates() {
    const params: any = {
      params: {
        project_id: this.center.project_id,
        group_id: this.center.group_id,
      },
    };
    GetTemplatesOfGroup(this, params).then((data: any) => {
      this.searchedTemplates = data;
    });
  }
  /**
   * @description 判断当前用户在当前机构下能否显示某个功能
   */
  private canThisRoleUse(tool: any) {
    if (!this.user["role"] || !this.user["role"]["名称"]) {
      return false;
    }
    let canUse = false;
    try {
      switch (tool) {
        case "批量导出":
          if (
            this.$store.state.roleControl[tool].indexOf(
              this.user["role"]["名称"]
            ) !== -1
          ) {
            canUse = true;
          }
          break;
        case "操作":
          if (
            this.$store.state.roleControl["数据录入"].indexOf(
              this.user["role"]["名称"]
            ) !== -1
          ) {
            canUse = true;
          }
          break;
        case "新增患者":
          if (
            this.$store.state.roleControl["数据录入"].indexOf(
              this.user["role"]["名称"]
            ) !== -1
          ) {
            canUse = true;
          }
          break;
      }
    } catch (e) {
      console.log(e);
    }
    return canUse;
  }
  /**
   * @description 跳转某个病人的某个诊次
   */
  private jumpZhenci(zhenci: any, patient: any) {
    this.$store.commit("updatePatient", patient);
    this.$store.commit("updateIfCompose", false);
    this.$router.push({
      path: "/main/storehouse/case/detail",
      query: {
        id: zhenci.zhenci_id,
      },
    });
  }
  private goDetail(item: any) {
    this.$store.commit("updatePatient", item);
    this.$store.commit("updateIfCompose", false);
    if (item["诊次"].length > 0) {
      this.$router.push({
        path: "/main/storehouse/case/detail",
        query: {
          id: item["诊次"][0].zhenci_id,
        },
      });
    } else {
      this.$router.push({
        path: "/main/storehouse/case/detail",
      });
    }
  }
  /**
   * @description 添加诊次
   */
  private addZhenci(patient: any) {
    const params: any = {
      project_id: this.center.project_id,
      group_id: this.center.group_id,
      patient_id: patient.patient_id,
      type: "0",
    };
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    AddZhenci(this, params)
      .then((data: any) => {
        loading.close();
        this.$store.commit("updatePatient", patient);
        this.$store.commit("updateIfCompose", false);
        this.$router.push({
          path: "/main/storehouse/case/detail",
          query: {
            id: data.zhenci_id,
          },
        });
      })
      .catch(() => {
        loading.close();
      });
  }
  getList() {
    const params: any = {
      institution_id: this.filters.institution_id,
      project_id: this.center.project_id,
      user_ids: this.filters.user_ids,
      order_field: this.filters.sort_field,
      order_value: this.filters.sort_value,
      current_page: this.filters.current_page,
      page_count: this.filters.page_count,
      search: this.filters.search,
      type: this.filters["类型"],
      discussion_id: this.filters["课题名称"]._id,
      zhenci_list: this.filters.zhenci_list,
    };
    // 处理高级筛选
    if (this.filters.timeWay === "自定义") {
      params["开始时间"] = this.filters.time[0];
      params["结束时间"] = this.filters.time[1];
      this.filters.ifPrimarySearched = true;
    }
    if (this.filters["研究指标"] && this.filters["研究指标"].length > 0) {
      this.filters.ifPrimarySearched = true;
      const arr: any[] = [];
      this.filters["研究指标"].map((item: any) => {
        const i = JSON.parse(JSON.stringify(item.selected));
        i.condition = item.result.condition;
        i.value = item.result.value;
        arr.push(i);
      });
      params["研究指标"] = arr;
    }
    localStorage.setItem("caseFilter", JSON.stringify(this.filters));
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    PatientList(this, params, loading)
      .then((data: any) => {
        loading.close();
        data.patients.forEach((ele: any) => {
          ele.ifShowMore = false;
        });
        this.patients = data.patients;
        this.filters.total_page = data.total_page;
        this.filters.total_count = data.total_count;
        if (this.patients.length === 0) {
          this.ifShowEmpty = true;
        } else {
          this.ifShowEmpty = false;
        }
      })
      .catch(() => {
        loading.close();
      });
  }
  /**
   * @description 获取操作模板
   */
  private getCaozuoTemplate() {
    const params: any = {
      params: {
        project_id: this.center.project_id,
      },
    };
    GetCaozuoTemplate(this, params).then((res: any) => {
      this.caozuoTems = res;
    });
  }
  /**
   * @description 初始化
   */
  mounted() {
    // 获取当前专病库的user信息
    const params: any = {
      params: {
        project_id: this.center.project_id,
      },
    };
    GetCurrentUserData(this, params).then((res: any) => {
      this.$store.commit("updateUser", res);
      this.getCaozuoTemplate();
      this.getZhenciOpt();
      if (location.href.indexOf("#reloaded") == -1) {
        location.href = location.href + "#reloaded";
        location.reload();
      }
      this.filters.institution_id = "";
      this.filters.user_ids = [];
      this.filters.timeWay = "";
      this.filters["研究指标"] = [];
      this.filters.time = [];
      this.filters.ifPrimarySearched = false;
      this.filters.sort_field = "更新时间";
      this.filters["条件"] = "";
      this.filters["课题名称"] = {};
      const d = localStorage.getItem("caseFilter") || "";
      if (d) {
        this.filters = JSON.parse(d);
      }
      this.fetchHospitals();
      this.getCreatorsList();
      this.getList();
      getTopicOpt(this).then((res: any) => {
        this.topicOpt = res;
      });
    });
  }
}
