



















import { Component, Vue, Prop } from "vue-property-decorator";
import * as _ from "lodash";
import axios, { resHandle } from "@/plugins/axios";
@Component({})
export default class SelectMultiSearch extends Vue {
  @Prop()
  private message: any;
  private resultOptions: any[] = [];
  @Prop()
  private type: any;
  @Prop()
  private pl: any;
  private messageChange() {
    this.$emit("update:message", this.message);
  }
  /**
   * @description 搜索
   */
  private fetchOptions(ev:any) {
    const params: any = {
      params: {
        type: this.type,
        search: ev
      }
    };
    axios.get("api/project/terms", params).then(({ data }) => {
      resHandle(this, data).then(() => {
        if (this.message.value && this.message.value.length > 0) {
          this.message.value.map((item: any) => {
            data.data.push(item);
          });
        }
        this.resultOptions = _.uniqBy(data.data, "id");
      });
    });
  }
  /**
   * @description 初始化
   */
  private mounted() {
    if (this.message.value && this.message.value.length > 0) {
      this.resultOptions = this.message.value;
      this.$forceUpdate();
    }
  }
}
